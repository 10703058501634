import React from 'react'

import { TermsProps } from '../../@interface/terms'

export const dataTermsOpenFinance: TermsProps = {
  title: 'Termos e Condições',
  subtitle: 'do Open Finance',
  update: '23 de fevereiro de 2023',
  about: {
    title: 'Termos e Condições',
    introduction:
      'Ao prosseguir com o consentimento no Open Finance, você fica ciente e concorda com o presente Termo em sua integralidade. Por este motivo, é importante que você leia atentamente.',
  },
  section: [
    {
      list: [
        {
          head: 'Informações Gerais',
          items: [
            {
              item:
                'O Open Finance, implementado pela Resolução Conjunta no 1 do Banco Central do Brasil (“BCB”) e Conselho Monetário Nacional (“CMN”), é um sistema de compartilhamento padronizado de seus dados, produtos e serviços, mediante seu devido prévio consentimento, que podem auxiliar na prestação de novos serviços e produtos, com maior eficiência tecnológica e operacional, por meio de abertura e integração de sistemas.',
            },
            {
              item:
                'Por implicar em manuseio dos seus dados, é imprescindível o seu entendimento e a sua autorização mediante esse consentimento, sendo que a finalidade específica, informações sobre os prazos, bem como os dados a serem compartilhados serão informados nessa jornada.',
            },
            {
              item:
                'A estrutura do Open Finance, assim como a Cora, cumprem toda a legislação aplicável sobre segurança da informação, privacidade e proteção de dados, respeitando sempre a Lei Geral de Proteção de Dados, e demais normas setoriais ou gerais sobre o tema. Caso queiram, confiram a nossa Diretiva de Privacidade, disponível em www.cora.com.br',
            },
          ],
        },
        {
          head: 'Acesso e Uso',
          items: [
            {
              item:
                'Dentro do Aplicativo da Cora haverá o ambiente Open Finance, onde você poderá acessar todo histórico de compartilhamento de dados, ativos ou expirados, recebidos ou transmitidos, realizar a gestão dos compartilhamentos, podendo alterá-los ou revogá-los a qualquer momento, bem como informações gerais sobre o Open Finance.',
            },
            {
              item:
                'Os dados poderão ser compartilhados com a Cora, e usados pra você consultar informações financeiras de outras instituições através da experiência de nosso aplicativo.',
            },
          ],
        },
        {
          head: 'Acesso para a Jornada do Consentimento',
          items: [
            {
              item:
                'A jornada do consentimento sempre iniciará no ambiente da Instituição Receptora, conforme canal de acesso disponibilizado, sendo que, nesse caso, esta será por meio do seu aplicativo mobile.',
            },
            {
              item:
                'Para trazer seus dados de outras instituições participantes do Open Finance, você deverá observar os seguintes passos:',
              child: [
                {
                  item: 'Clique no item ”conta” e vá até a opção Open Finance;',
                  listStyleType: 'disc',
                },
                {
                  item: 'Selecione a opção ”Trazer dados”;',
                  listStyleType: 'disc',
                },
                {
                  item:
                    'Escolha a instituição de onde deseja trazer os dados para Cora;',
                  listStyleType: 'disc',
                },
                {
                  item:
                    'Após, defina quais dados você deseja realizar o compartilhamento;',
                  listStyleType: 'disc',
                },
                {
                  item:
                    'Defina o período e aceite os termos para ser direcionado para o aplicativo da instituição selecionada;',
                  listStyleType: 'disc',
                },
                {
                  item:
                    'Autorize o compartilhamento de dados no aplicativo da instituição selecionada;',
                  listStyleType: 'disc',
                },
                {
                  item:
                    'Ao ser redirecionado para o aplicativo da Cora você pode realizar um novo compartilhamento ou visualizar o resumo da operação que acabou de concluir.',
                  listStyleType: 'disc',
                },
              ],
            },
          ],
        },
        {
          head: 'Responsabilidades',
          items: [
            {
              item:
                'A Cora se comprometem a tratar os dados cadastrais e/ou transacionais nos limites do consentimento concedido pelo Usuário, bem como declara zelar pelos dados pessoais dos usuários de acordo com as diretrizes estabelecidas pela Lei Geral de Proteção de Dados Pessoais e demais legislações e regulamentações vigentes.',
            },
            {
              item:
                'A Instituição com a qual o usuário possui relacionamento (Transmissora de Dados ou Detentora de Conta) tem a responsabilidade de transmitir os dados cadastrais e/ou transacionais, nos limites do consentimento realizado pelo Usuário.',
            },
            {
              item:
                'Caso os dados compartilhados pelo Usuário envolvam, direta ou indiretamente, quaisquer dados de terceiros, a Cora se compromete a zelar por todos os dados recepcionados, sempre observando a legislação de Proteção de Dados e de Open Finance, bem como demais normas porventura aplicáveis.',
            },
            {
              item:
                'A qualquer momento o Usuário poderá revogar o consentimento, tanto pelos canais de atendimento, como pelos canais da Transmissora de Dados, ressalvando que o único canal disponibilizado pela Cora é o seu aplicativo mobile.',
            },
            {
              item:
                'Os dados pessoais dos clientes que eventualmente forem necessários para resolução de uma disputa entre a Cora e a Transmissora de Dados ou Detentora de Conta,serão tratados observando as convenções existentes entre as instituições envolvidas, as normas que tratam da Proteção de Dados e do Open Finance, bem como demais normas aplicáveis, conforme o caso.',
            },
          ],
        },
      ],
    },
  ],
}
