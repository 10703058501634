import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsOpenFinance } from '../data/terms/terms-open-finance'

const OpenFinanceTermsConditionsPage = (): JSX.Element => (
  <TermsPages
    data={dataTermsOpenFinance}
    linkCanonical="termos-e-condicoes-do-open-finance"
  />
)

export default OpenFinanceTermsConditionsPage
